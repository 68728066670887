import React, { Component } from 'react';
import '../css/EventStatus.css';
import { BrowserView, MobileView } from 'react-device-detect';
import utilities from '../Utilities/utilities';

export default class EventStatus extends Component {

    state = {
        event: {},
    }

    data = JSON.parse(sessionStorage.getItem("data"));

    componentDidMount() {
        this.getData();
    }

    getData() {
        for (var i = 0; i < this.data.events.length; i++) {
            if (this.props.event === this.data.events[i].name) {
                this.setState({ event: this.data.events[i] });
            }
        }
    }

    render() {
        return (
            <div className="eventStatusContainer">
                <div className="eventStatusTop">
                    <p className="eventStatusEventName cursive">{utilities.getUserFriendlyEventName(this.state.event.name)}</p>
                    <BrowserView>
                        <p className="eventStatusDate cursive">{this.state.event.day + this.state.event.date + ", " + this.state.event.year}</p>
                    </BrowserView>
                    <MobileView>
                        <p className="eventStatusDate cursive">{this.state.event.day}<br />{this.state.event.date + ", " + this.state.event.year}</p>
                    </MobileView>
                </div>
                <div className="eventStatusBottom">
                    <p className="eventAttendanceStatus" data-eventname={this.props.event}></p>
                    <p className="location">
                        <span className="locationName">{this.state.event.location}</span><br />
                        <span>{this.state.event.address}</span><br />
                        <span>{this.state.event.city}</span>
                    </p>
                </div>
            </div >
        );
    }
}
